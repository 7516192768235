#timeZoneSelector {
    margin: 1rem 0;
    padding: 2rem 0;
    position: relative;
}

#timezone {
    display: flex;
}

.labelWrapper {
    display: flex;
    align-items: center;
}
.zoneLabel {
    margin: 1rem 2rem;
    font-size: 1.6rem;
}

.wrapper {
    width: 80vw;
    padding: 2rem;
    display: flex;
}

.regionList {
    padding: 0;
    padding-right: 2rem;
    border-right: .1rem solid rgb(180, 180, 180);
    width: 20rem;
    list-style: none;
    
}

.regionLabel {
    list-style: none;
    text-align: left;
    padding: 1rem;
    border-bottom: .1rem solid rgb(180, 180, 180);
    color: .1rem solid rgb(100, 100, 100);
    transition: .3s;
} 

.regionLabel:hover {
    background-color: #bbb;
}



  .zoneWrapper {
    height: 45rem;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
  }

  .zoneHeader {
    display: flex;
    align-items: center;
    justify-content: stretch;
    margin: 0 2rem;
   

  }

  .filterWrapper {
    flex-grow: 2;
    display: flex;
    justify-content: stretch;
  }

  .subRegionList {
    margin: 0;
    height: 50rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 1.4rem;
    overflow-x: auto;
   
  }
  .subRegionList:-webkit-scrollbar {
    width: '0.4em'
  }
  .subRegionList:-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00)
  }
  .subRegionList:-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.1);
    outline: 1px solid slategrey
  }
  

  .subRegionItem {
    padding: 1rem;
    list-style: none;
    text-align: left;
    border-radius: .3rem;
    cursor: pointer;
    overflow: hidden;
    min-width: 16rem;
    max-width: 20rem;
  }

  .subRegionItem:hover {
    color: white;
    background-color: var(--primary);
  }

  .filterInput {
    margin: 0 0 0 2rem;
    padding: .5rem 0;
    border: 0;
    font-size: 1.8rem;
    font-weight: 200 !important;
    background-color: transparent;
    border-bottom: .1rem solid rgb(180, 180, 180);
    flex-grow: 2;
    color: #333;
    transition:.3s;
  }

  .filterInput:active, .filterInput:focus, .filterInput:target{
    border: none;
    outline: none;
    border-bottom: .1rem solid var(--primary);
  }
  .icon {
    font-size: 2rem;
    cursor: pointer;
    transition: .3s;
    color: var(--primary);
  }