.card {
    margin: 5px;
    
    
}

.card-header {
    border-radius: 10px 10px 0px 0px;
    padding-left: 10px;
    background-color: var(--secondary);
    color: white;
}

.card-header h3 {
    margin: 0;
    padding: 5px;
}


.primary-card {
    background-color: var(--primary);
}

.secondary-card {
    background-color: var(--secondary);
}