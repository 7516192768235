.pageHeaderWrapper {
    position: fixed;
    margin: 0;
    margin-left: 8rem;
    width: calc(100% - 80px);
    padding: 5px;
    background-color: #ccc;
    color: #555;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;

    z-index: 500;
  }

  .titleWrapper {
      display: flex;
      flex-direction: column;
  }
  
  .eventTitle {
      margin: 0;
      margin-left: 1rem;
      
      width: 100%;
     
      padding: .5rem 0rem;
      border-radius: .3rem;
      font-size: 1.6rem;
  }
  .pageTitle {
      margin: 0;
      font-weight: 200;
      font-size: 2rem;
      margin-left: 1rem;
  }

  .eventToggleWrapper {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .welcome-text {
      padding: 0;
      margin-right: 10px;
      font-size: 1.8rem;
      font-weight: 200;
  }

  .logout {
      background-color: transparent;
      display: block;
      border: none;
      color: var(--title-color);
      padding: 10px;
      font-size: 2rem;
      transition: .3s;
      cursor: pointer;
  }

  .logout:hover {
      color: var(--primary-500);
  }

  .logout:active, 
  .logout:focus {
    border: none;
    outline: none;
}
  