.switcher {
  position: relative;
  width: 160px;
  height: 44px;
  border-radius: .5rem;
  margin: 0rem 1rem;
  margin-top: -.5rem;
}

.switcher input {
  appearance: none;
  position: relative;
  width: 160px;
  height: 44px;
  border-radius: .5rem;
  
  outline: none;
}
.switcher input:before,
.switcher input:after {
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* color: #ffffff; */
  color: var(--title-color);
}
.switcher input:before {
  content: 'OPEN';
  left: 20px;
}
.switcher input:after {
  content: 'CLOSED';
  right: 20px;
}
.switcher label {
  z-index: 1;
  position: absolute;
  top: 10px;
  bottom: 5px;
  border-radius: .5rem;
}
.switcher input {
  transition: 0.25s -0.1s;
}
.switcher input:checked {
  /* background-color: #ffffff; */
  /* background-color: var(--primary); */
}
.switcher input:checked:before {
  /* color: #ffffff; */
  
  color: white;
  transition: color 0.5s 0.2s;
}
.switcher input:checked:after {
  color: #999;
  transition: color 0.5s;
}
.switcher input:checked + label {
  left: 1.5rem;
  right: 8.5rem;
  /* background: black; */
  transition: left 0.5s, right 0.4s 0.2s;
}
.switcher input {
  transition: background 0.5s -0.1s;
}
.switcher input:before {
  color: #999;
  transition: color 0.5s;
}
.switcher input:after {
  color: white;
  transition: color 0.5s 0.2s;
}
.switcher input + label {
  left: 8rem;
  right: .5rem;
  background: var(--title-color);
  transition: left 0.4s 0.2s, right 0.5s, background 0.35s -0.1s;
}

.aysModal {
  padding: 0;
  overflow: hidden;
}