.pc-stats {
    display: flex;
    flex-wrap: wrap;
    max-height: 95vh;
    overflow-y: auto;
}

.content-wrapper {
    padding: 5rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

}
.stat-container {
    margin: 2rem;
    width: 100%;
    min-width: 30%;
    padding: 3rem;
    background-color: white;
}