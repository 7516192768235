#content-container {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    background: whitesmoke;
    padding: 20px;
    border-radius: 8px;
    justify-content: flex-start;  
}

.cc-outer-content {
    display: flex;
    flex-direction: column;
    border: 0px solid var(--title-color);
    border-radius: 8px;
    width: 100%;
}

.trans-details-title {
    margin: -1rem 2rem .5rem 1rem;
    width: fit-content;
    padding: 0 10px;
    font-size: 1.6rem;
    color: var(--title-color);
}

.cc-inner-content {
    padding: 10px;
    display: flex;
    color: #333;
}