.sub-menu__wrapper {
    margin: 0;
    margin-bottom: 5px;
    margin-top: 55px;
    position: fixed;
    width: calc(100% - 80px);
    z-index: 499;
    padding: 0;
    height: 40px;
    background-color:#ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, .6);

}

.sub-menu-list {
    list-style: none;
    display: flex;
    font-size: 1.5rem;
    padding-left: 0;
}

.sub-menu-item {
    padding: 3px 15px;
    color:white;
    border-right: 1px dotted #999;
}

.sub-menu-item-btn {
    background-color: transparent;
    outline: none;
    border: none;
    color:#555;
    font-size: 1.5rem;
    transition: .3s;
    cursor: pointer;
    display: block;
}

.sub-menu-item-btn:hover,
.sub-menu-item-btn:focus {
    outline: none;
    border: none;
    color: var(--primary);
}

