.pageWrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-grow: 2;
    width: 100%;
    height: 90vh;
    padding-top: 6rem;
}

.attendeesContainer {
    position: relative;
    width: 100%;
}

.whosonlineWrapper {
    position: absolute;
    top: 22rem;
    left: 8rem;
    bottom: 0;
    width: 30rem;
    display: flex;
    
}

.card {
    color: white;
    background-color: rgba(10, 10, 10, .8);
}