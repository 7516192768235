.create-event-form-wrapper {
    width: 90vw;
    display: flex;
    justify-content: center;
    margin: 0 auto;
}
.create-event-actions {
    display: flex;
    justify-content: flex-end;
}

.event-uri-label {
    margin: .3rem;
    
    padding: .2rem 1rem;
    padding-bottom: 1rem;
    color: white;
    background-color: #333;
    border-radius: .3rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 400;
}

.typeListWrapper {
    display: flex;
    flex-wrap: wrap;

}