.modal {
    position: relative;
    padding: 0;
}

.cancelBtn {
    margin: 0;
    padding: 1rem 2rem;
    font-weight: 800;
    font-size: 1.6rem;
    border-radius: 0.5rem 0 0.5rem 0;
    background-color: var(--primary);
    color: white;
    position: absolute;
    border: 0;
    top: 0rem;
    left: 0rem;
    cursor: pointer;
}