.errorModal {
    padding: 0;
}

.title {
    padding: 0 2rem;
    font-size: 3rem;
    margin-bottom: 0;
}

.text {
    padding: 0 2rem;
    max-width: 40rem;
    color: #333;
    white-space: pre-wrap;
    text-align: center;
}

.modalBtn {
    margin: 0;
    padding: 2rem;
    background-color: rgba(0, 0, 0, .3);
    margin-top: 2rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 0;
    font-size: 1.8rem;
    transition: .3s;
}

.modalBtn:hover {
    background-color: rgba(0, 0, 0, .6);
}