.ringContainer {
    margin: 0;
    padding: 0;
    position: relative;
}

.circle {
    width: 15px;
    height: 15px;
    background-color: var(--primary);
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.ringring {
    border: 3px solid var(--primary);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    height: 25px;
    width: 25px;
    position: absolute;
    left: -8px;
    top: -8px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite; 
    -webkit-animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite; 
    opacity: 0.0
}
@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

@keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}