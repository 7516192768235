
.loading {
    width: 100%;
    display: flex;
    justify-content: center;
}

.loadingContiner {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loadingMessage {
    font-size: 1.6rem;
    font-weight: 300;
    color: #333;
}
.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: var(--primary) var(--primary) transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after,
  .loader::before {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #333 #333;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  .loader::before {
    width: 32px;
    height: 32px;
    border-color: var(--primary) var(--primary) transparent transparent;
    animation: rotation 1.5s linear infinite;
  }

  /* black theame */
  .black {
    border-color: black black transparent transparent;
  }
  .black::after,
  .black::before {
    border-color: transparent transparent #333 #333;
  }
  .black:after {
    border-color: black black transparent transparent;
  }

  /* light theame */
  .white {
    border-color: whiteSmoke whitesmoke transparent transparent;
  }
  .white::after,
  .white::before {
    border-color: transparent transparent white white;
  }
  .white:after {
    border-color: whiteSmoke whiteSmoke transparent transparent;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
      

  