#areYouSure {
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;

}

.header, .body {
    padding: 1rem 3rem;
}

.header {
    padding-bottom: 0;
}

.title {
    font-weight: 300;
    margin-bottom: 0;
}

.body {
    padding-bottom: 3rem;
    white-space: pre-wrap;
    text-align: center;
}

.actions {
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    border-top: .1rem solid #ccc;
}

.okBtn, .cancelBtn {
    padding: 0;
    margin: 0;
    width: 50%;
    padding: 2rem;
    font-size: 2rem;
    border: 0;
    background-color: rgba(0, 0, 0, .1);
    transition: .3s;
}

.okBtn {
    border-right: .1rem solid #ccc;
    color: white;
    font-weight: 600;
}

.cancelBtn {
    color: white;
    background-color: #333;
    border-radius: 0 0  1rem 0;
}

.okBtn:hover, .cancelBtn:hover {
    background-color: rgba(0, 0, 0, .3);
}