#avatar {
  position: relative;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--primary);
  border: .1rem solid var(--primary);
  color: white;
}

.image {
  width: 4.8rem;
  height: 4.8rem;
  object-fit: cover;
}

 .text {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  font-size: 1.5rem;
}

