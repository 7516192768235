.logo__wrapper {
    margin: 15px 0px 5px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.logo-img{
    height: 100%;
}