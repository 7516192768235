.mainList {
    position: absolute;
    overflow-y: auto;
    bottom: 0;
    top: 4.5rem;
    display: flex;
    flex-direction: column;
    width:100%;
}

.mainList::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .mainList {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }