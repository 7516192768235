.mapContiner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    border-left: 0px solid #333;
    z-index: 0;
}

.resetBtn {
    visibility: visible;
    position: absolute;
    bottom: 4rem;
    right: 1rem;
    border: 1px solid var(--primary);
    background-color: var(--primary);
    color: white;
    padding: 1rem 2rem;
    border-radius: .5rem;
    transition: .3s;
}

.resetBtn:hover {
    background-color: var(--primary-500);
}

.hidden {
    visibility: hidden;
}