.row {
    margin: .5rem;
    padding: 1rem 2rem;
    color: #444;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr 1fr 1fr;
    justify-content: flex-start;
    align-items: center;
    min-width: 15rem;
    height: 6rem;
    cursor: pointer;
    transition: .3s;
}

.row:hover {
    background-color: #e6e6e6;
}



.logo {
    height: 100%;
    width: 100%;
    width: auto;
    max-height:6rem;
}

.event-list-item:hover {
    cursor: pointer;
    background-color: var(--secondary);
}

.cell {
    position: relative;
    margin: 0;
    padding: .5rem;
    font-size: 2rem;
    min-width: 10rem;

}

.text {
    font-size: 1.6rem;
    font-weight: 300;
}

.title {
    font-weight: 600;
}