@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab:wght@100;200;300;400;500;600;700&family=Urbanist:wght@100;200;300;400;500;600;800;900&display=swap');

:root {
  /* --primary-100: #ef4390;
  --primary: #eb1e79;
  --primary-500: #8d0c46; */

  --alert: #D0342C;
  --warning: #ffeb3b;
  --success: #4E9B47;
  
  --primary-100: #77b2d5;
  --primary: #2c698d;
  --primary-500: #1e4861;

  --title-color: #555;

--secondary-100: #77d5c9;
  --secondary: #2C8D81;
  --secondary-500: #1e6159; 

  --ternary: #35AC45;
  --ternary-500: #125C1C;

  --input-color: #999999;
  --input-border: #8c8c8c;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;

  --input-border-focus: var(--primary-500);

  --group-color: #333;
  --group-border: var(--input-border);
  --group-background: #ededed;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: var(--primary);

}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--secondary) !important;
}

html {
  margin: 0;
  position: relative;
  min-height: 100vh;
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Urbanist', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  color: var(--primary);
  background-color: whitesmoke;
}

.center {
  margin: 0 auto;
  text-align:  center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.left {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1750px;
}

.page-wrapper {
  margin: 0 auto;
  padding-top: 9rem;
  margin-bottom: 5.6rem;
  width: 100%;
  max-width: 1750px;
  min-height: 500px;
}

a {
  color: var(--primary);
  text-decoration: none;
}


.clear-header {
  padding-top: 90px !important;
}

a:hover {
  color: var(--primary-500);
}

#root {
  width: 100%;
  display: flex;
}

.text-error {
  font-size: 1.2rem;
  color: darkred;
}

.page-title {
  margin: 2rem ;
  font-weight: 200;
  font-size: 4rem;
  color: grey;
  display: block;
  width: 100%;
  border-bottom: .1rem solid var(--primary);
}

