.sidebar__wrapper {
  position: fixed;
  width: 80px;
  height: 100vh;
  background-color: var(--primary);
  border-left: 1px solid #a0a0a0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, .6);
  z-index: 501;
}

.link-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
}

.sb-logo {
  height: 5rem;
  padding: 0.5rem;
}

.events-link {
  width: 100%;
}