
.regionFlag {
    width: 2rem;
}

.flag {
    width: 1.7rem;
    height: 1.7rem;
    vertical-align: middle;
    margin-top: -3px;
}

.regionSelFlag {
    display: inline-block;
    width: 16px;
    height: 16px;
}



