.wrapper{
    position: relative;
    margin: .5rem 2rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 30rem;
    cursor: pointer;
}

.thumb {
    width: 30rem;
    height: 20rem;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
font-size: 8rem;


}

.title {
    margin: .5rem;
    font-size: 2rem;
    text-transform: capitalize;
}

.desc {
 font-size: 1.4rem;
 margin-bottom: 10rem;
}