.listItem {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(8deg, rgba(0,0,0,0.9444152661064426) 20%, rgba(51,51,51,0.8743872549019608) 100%);
    background: -webkit-linear-gradient(8deg, rgba(0,0,0,0.9444152661064426) 20%, rgba(51,51,51,0.8743872549019608) 100%);
    background: linear-gradient(8deg, rgba(0,0,0,0.9444152661064426) 20%, rgba(51,51,51,0.8743872549019608) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#333333",GradientType=1);
    margin: .2rem 0;
    font-size: 1.2rem;
    color: white;
}

.titleContainer {
    display: flex;
    align-items: center;
}
.username {
    margin: .3rem;
    font-size: 1.6rem;
    font-weight: 300;
}

.email {
    margin: .3rem;
  
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--primary);
}

.loggedin {
    margin: .3rem;
    font-size: 1.4rem;
    font-weight: 600;
}

.icon {
    font-size: 2.8rem;
    margin: 0;
    color: white;
    margin-left: -1rem;
}

.deviceIcon {
    font-size: 2rem;
    margin: 0;
    color: #999;
}

.userInfoContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    
    width: 100%;
   
}

.avatarContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-right: 1rem;
}

.flag {
    position: absolute;
    padding: 0;
    bottom: -1rem;
    right: -.8rem;
    height: 2rem;
}

.ip {
    font-size: 1.4rem;
}

.infoContainer {
    display: flex;
    color: grey;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    width: 100%;
}

.iconsContainer {
    display: flex;
}

.infoContainer p {
    margin: 1rem;
    margin-bottom: 0;
}

