#eventList{
    overflow-y: auto;
    max-height: 90vh;
}

.pageTitle {
    margin: 2rem;
    font-size: 3rem;
    font-weight: 200;
    color: #444;
}
.tableHeader {
    margin: .5rem;
    padding: .3rem 2rem;
    color: #fff;
    background-color: #444;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr 1fr  1fr;
    justify-content: flex-start;
    align-items: center;
    min-width: 15rem;
    font-size: 1.6rem;
    font-weight: 400;

    
}
.conatiner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    padding: 0 2rem;
}
.noEl {
    margin: 0;
    padding:0;
    display: flex;
}

.box{
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;
}

.title{
    margin: 0;
    font-size: 3rem;
    font-weight: 300;
    color: grey;
}
.text {
    margin: .3rem;
    font-size: 1.4rem;
    color: grey;
}